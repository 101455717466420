import revive_payload_client_WRCjL8qmDn from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_h7bqfckjmz6lxtwwuvlf36zoci/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_wNYjvX8Kud from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_h7bqfckjmz6lxtwwuvlf36zoci/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_E62LSc4XlL from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_h7bqfckjmz6lxtwwuvlf36zoci/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_8ceP9ACcU9 from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_h7bqfckjmz6lxtwwuvlf36zoci/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_pp99UigKlC from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_h7bqfckjmz6lxtwwuvlf36zoci/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ozVGgvERwB from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_h7bqfckjmz6lxtwwuvlf36zoci/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_1Sr8yOblNP from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_h7bqfckjmz6lxtwwuvlf36zoci/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ZOBK3OEaRH from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.28.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/.nuxt/components.plugin.mjs";
import prefetch_client_NhOCthPDPc from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_h7bqfckjmz6lxtwwuvlf36zoci/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/.nuxt/primevue-plugin.mjs";
import plugin_client_YMhhlx57yl from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt-primevue@0.3.1_magicast@0.3.5_rollup@4.28.1_vue@3.5.13_typescript@5.7.2_/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import plugin_client_v9jhAPblMl from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.28.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_fMOyHGjvbz from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.28.1_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_TpPc9NuPhz from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.28.1_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _01_sentry_axzTOhSqTR from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/packages/core/plugins/01.sentry.ts";
import _02_tokens_wrFGe2jZkz from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/packages/core/plugins/02.tokens.ts";
import _03_notifications_ohdHidFFIj from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/packages/core/plugins/03.notifications.ts";
import _04_tooltip_LD8CWP4Lfg from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/packages/core/plugins/04.tooltip.ts";
import _05_close_xq2JRofxrT from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/packages/core/plugins/05.close.ts";
import user_0y9HlFsUwP from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/plugins/user.ts";
export default [
  revive_payload_client_WRCjL8qmDn,
  unhead_wNYjvX8Kud,
  router_E62LSc4XlL,
  payload_client_8ceP9ACcU9,
  navigation_repaint_client_pp99UigKlC,
  check_outdated_build_client_ozVGgvERwB,
  chunk_reload_client_1Sr8yOblNP,
  plugin_vue3_ZOBK3OEaRH,
  components_plugin_KR1HBZs4kY,
  prefetch_client_NhOCthPDPc,
  primevue_plugin_egKpok8Auk,
  plugin_client_YMhhlx57yl,
  plugin_client_v9jhAPblMl,
  switch_locale_path_ssr_fMOyHGjvbz,
  i18n_TpPc9NuPhz,
  _01_sentry_axzTOhSqTR,
  _02_tokens_wrFGe2jZkz,
  _03_notifications_ohdHidFFIj,
  _04_tooltip_LD8CWP4Lfg,
  _05_close_xq2JRofxrT,
  user_0y9HlFsUwP
]