import { default as _91ticket_93EcoF9s1xeCMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/2fa/enable/[ticket].vue?macro=true";
import { default as _91ticket_933Xu3dsWyYMMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/2fa/verify/[ticket].vue?macro=true";
import { default as indexXdqUWV9mFwMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/index.vue?macro=true";
import { default as _91ticket_93ZVwY2LgxnQMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/recover-password/[ticket].vue?macro=true";
import { default as index4cnejB3MZlMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/recover-password/index.vue?macro=true";
import { default as sign_45updpSSoAZgkjMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/sign-up.vue?macro=true";
import { default as change_45passwordfOV9WJhE1vMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/change-password.vue?macro=true";
import { default as indexfgr5sBD8auMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/index.vue?macro=true";
import { default as overviewRBWkk0YkfgMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/overview.vue?macro=true";
import { default as editrM3uraPVh7Meta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/profile/edit.vue?macro=true";
import { default as verify_45identitygPRvg8PD6XMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/verify-identity.vue?macro=true";
export default [
  {
    name: "auth-2fa-enable-ticket",
    path: "/auth/2fa/enable/:ticket()",
    meta: _91ticket_93EcoF9s1xeCMeta || {},
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/2fa/enable/[ticket].vue")
  },
  {
    name: "auth-2fa-verify-ticket",
    path: "/auth/2fa/verify/:ticket()",
    meta: _91ticket_933Xu3dsWyYMMeta || {},
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/2fa/verify/[ticket].vue")
  },
  {
    name: "auth",
    path: "/auth",
    meta: indexXdqUWV9mFwMeta || {},
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/index.vue")
  },
  {
    name: "auth-recover-password-ticket",
    path: "/auth/recover-password/:ticket()",
    meta: _91ticket_93ZVwY2LgxnQMeta || {},
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/recover-password/[ticket].vue")
  },
  {
    name: "auth-recover-password",
    path: "/auth/recover-password",
    meta: index4cnejB3MZlMeta || {},
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/recover-password/index.vue")
  },
  {
    name: "auth-sign-up",
    path: "/auth/sign-up",
    meta: sign_45updpSSoAZgkjMeta || {},
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/sign-up.vue")
  },
  {
    name: "change-password",
    path: "/change-password",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/change-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexfgr5sBD8auMeta || {},
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/index.vue")
  },
  {
    name: "overview",
    path: "/overview",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/overview.vue")
  },
  {
    name: "profile-edit",
    path: "/profile/edit",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/profile/edit.vue")
  },
  {
    name: "verify-identity",
    path: "/verify-identity",
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/verify-identity.vue")
  }
]