export function parseToken(url: string) {
  try {
    const { searchParams } = new URL(url)
    if (searchParams.has("st")) {
      return searchParams.get("st")
    }

    if (searchParams.has("logout")) {
      return null
    }
  } catch (e) {
    console.error("Could not parse token", e)
  }
}
