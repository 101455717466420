export function useAutoTitle() {
  const title = ref("")
  useHead({
    title,
  })

  async function setTitle() {
    await nextTick()
    const h1 = document.querySelector("h1")
    title.value = h1?.textContent || ""
  }

  const route = useRoute()
  watch(route, setTitle)
  onMounted(setTitle)
}
